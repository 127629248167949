<template>
    <div>
        <b-modal ref="modalSelectHorse" @show="init_component">
            <template slot="modal-header" slot-scope="{ close }">
                <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
                <h2 class="mx-auto my-auto">{{ $t('monte.select_horses') }}</h2>
            </template>
            <template slot="default">
				<e-select
                    v-model="selected_horses"
                    id="id"
                    label="name"
                    track-by="id"
                    :placeholder="$t('horse.search_horse')"
                    :options="horses"
                    :searchable="true"
                    :allow-empty="false"
                    :multiple="true"
				>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                </e-select>
            </template>

            <template slot="modal-footer" class="justify-content-center">
                <b-button variant="primary" @click="print_infos">
                    <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/>
                    <font-awesome-icon v-else :icon="['fal', 'long-arrow-right']" class="mr-1"/>{{ $t('global.suivant') }}
                </b-button>
                <b-button @click="closeModal()">
                    <font-awesome-icon :icon="['fal', 'times']" class="mr-1"/> {{ $t('global.annuler') }}
                </b-button>
            </template>
        </b-modal>

        <ModalPrintInfosHorse ref="modal_print_infos_horse"/>
    </div>
</template>


<script type="text/javascript">
	export default {
        props:['date', 'componentName', 'lieu_id'],
        data () {
            return {
                processing: false,
                planning: null,
                selected_horses: [],
                horses: []
            }
        },
        methods: {
            init_component() {
                this.horses = []
                this.selected_horses = []
                this.processing = false

                this.planning.planning_horse.forEach(stallion => {
                    this.horses.push({
                        id: stallion.horse_id,
                        name: stallion.horse_nom
                    })
                })

                if(this.planning.infos) {
                    for(let i in this.planning.infos) {
                        for(let y in this.planning.infos[i]) {
                            this.planning.infos[i][y].forEach(info => {
                                if(info.mare) {
                                    this.horses.push({
                                        id: info.mare.horse_id,
                                        name: info.mare.horse_nom
                                    })
                                }
                            })
                        }
                    }
                }
                else {
                    for(let i in this.planning.planning_schedule) {
                        this.planning.planning_schedule[i].infos.forEach(info => {
                            if(info.sms) {
                                this.horses.push({
                                    id: info.mare.horse_id,
                                    name: info.mare.horse_nom
                                })
                            }
                        })
                    }
                }
            },

            openModal(planning) {
                this.planning = planning
                this.$refs.modalSelectHorse.show()
            },

            closeModal() {
                this.$refs.modalSelectHorse.hide()
            },

            print_infos() {
                this.processing = true
                let horses_ids = []
                this.selected_horses.forEach(horse => {
                    horses_ids.push(horse.id)
                })
                this.closeModal()
				this.$refs.modal_print_infos_horse.openModal(horses_ids)
			}
        },
        components: {
            ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse')
        }
	}
</script>
